<template>
  <div>
    <a-table :columns="columns"
             :data-source="data"
             :loading="loading"
             :pagination="false"
             :rowSelection="rowSelection"
             bordered
             row-key="id"
             class="table small-cell-table">
      <template slot="action" slot-scope="text, record">
        <a-button type="link" class="btn" @click="preview(record)">浏览证书</a-button>
        <a-button type="link" class="btn" @click="download(record)">下载证书</a-button>
        <a-button type="link" class="btn" @click="onEdit(record)">修改证书图片</a-button>
      </template>
      <template slot="footer">
        <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                    class="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"></Pagination>
      </template>
    </a-table>

    <ImageViewer ref="imageViewerRef"/>
    <ModifyCertificate ref="modifyCertificateModalRef"
                       :columns="columns"
                       @success="onRefresh"/>
  </div>
</template>

<script>
import Common from '@/utils/Common';
import {
  batchDownloadCertificates,
} from './api';
import {
  getCertificateOnlineList,
  getCertificateOnlineListDownload,
} from './api/online';

import ImageViewer from '@/components/ImageViewer.vue';
import Pagination, { getPagination } from '@components/Pagination.vue';
import ModifyCertificate from './components/ModifyCertificate.vue';

const columns = [
  {
    width: '180px',
    align: 'center',
    dataIndex: 'regional_org_name',
    key: 'regional_org_name',
    title: '大区',
  },
  {
    width: '180px',
    align: 'center',
    dataIndex: 'small_regional_org_name',
    key: 'small_regional_org_name',
    title: '小区',
  },
  {
    width: '180px',
    align: 'center',
    dataIndex: 'created_user_data.orgname',
    key: 'created_user_data.orgname',
    title: '经销商',
  },
  {
    width: '110px',
    align: 'center',
    dataIndex: 'cert_post_name',
    key: 'cert_post_name',
    title: '获得证书岗位',
  },
  {
    width: '70px',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
    title: '证书ID',
  },
  {
    width: '150px',
    align: 'center',
    dataIndex: 'certificate_number',
    key: 'certificate_number',
    title: '证书编号',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'publish_time_1',
    key: 'publish_time_1',
    title: '发证时间',
  },
  {
    width: '70px',
    align: 'center',
    dataIndex: 'created_user_data.truename',
    key: 'created_user_data.truename',
    title: '姓名',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'created_user_data.idcard',
    key: 'created_user_data.idcard',
    title: '身份证号',
  },
  {
    width: '110px',
    align: 'center',
    dataIndex: 'created_user_data.username',
    key: 'created_user_data.username',
    title: '手机号',
  },
  {
    width: '110px',
    align: 'center',
    dataIndex: 'created_user_data.postname',
    key: 'created_user_data.postname',
    title: '奔腾苑目前岗位',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'created_user_data.user_status_str',
    key: 'created_user_data.user_status_str',
    title: '账号封禁状态',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'rel_data.title',
    key: 'rel_data.title',
    title: '关联任务',
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'Online',
  components: {
    ImageViewer,
    Pagination,
    ModifyCertificate,
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: (this.expandedRowKeys || []),
        onChange: (selectedRowKeys) => {
          this.expandedRowKeys = selectedRowKeys;
        },
        // getCheckboxProps: (record) => ({
        //   props: {
        //     disabled: !this.expandedRowKeys.includes(record.id) && this.expandedRowKeys?.length >= this.maxDownloadCount,
        //   },
        // }),
      };
    },
  },
  data() {
    return {
      loading: false,
      downloading: false,
      maxDownloadCount: 0,

      params: {},
      expandedRowKeys: [],

      columns,
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    onSearch(params) {
      this.params = { ...params };
      return this.getData(1, this.pagination.pageSize);
    },
    onRefresh() {
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    onReset(params) {
      this.onSearch(params);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      if (this.loading) {
        return Promise.reject(new Error('Loading'));
      }
      this.loading = true;
      this.setParentStatus('loading', true);

      const params = {
        ...this.params,
        page,
        per_page: pageSize,
      };

      const data = await getCertificateOnlineList(params).finally(() => {
        this.loading = false;
        this.setParentStatus('loading', false);
      });

      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    onExport() {
      if (!this.expandedRowKeys.length) {
        this.$message.info('请选择要下载的证书');
        return;
      }
      batchDownloadCertificates(this.expandedRowKeys.join(','), this.$store.state.User.token);
      this.expandedRowKeys = [];
    },

    onExportExcel(params) {
      if (this.downloading) {
        return;
      }
      this.downloading = true;
      this.params = { ...params };
      getCertificateOnlineListDownload({
        ...this.params,
        page: this.pagination.current,
        per_page: this.pagination.pageSize,
        is_download: 1,
      }).finally(() => {
        this.downloading = false;
      }).catch(() => {
        this.downloading = false;
      });
    },

    setParentStatus(type, status) {
      if (!this.$parent?.$parent) {
        return;
      }
      if (this.$parent.$parent[type] !== null && this.$parent.$parent[type] !== undefined) {
        this.$parent.$parent[type] = status;
      }
    },

    preview(record) {
      if (this.$refs.imageViewerRef?.show) {
        this.$refs.imageViewerRef.show({ url: record?.file_data?.url || '' });
      }
    },
    download(record) {
      const url = record?.file_data?.download_url || '';
      const title = record?.file_data?.down_title || '';
      Common.downloadFile(url.indexOf('?') > -1 ? `${url}&title=${title}` : `${url}?title=${title}`, title);
    },

    onEdit(record) {
      if (this.$refs.modifyCertificateModalRef?.show) {
        this.$refs.modifyCertificateModalRef.show({ ...(record || {}) });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  margin-left: 0;
  margin-right: 0;
  padding-left: 6px;
  padding-right: 6px;
}
</style>
