import { stringify } from 'qs';
import { baseUrl } from '@/Config';
import store from '@/store/index';

import request from '@/utils/Request';
import { downloadFileExtra } from '@/utils/download';

// 证书列表-线上任务
export function getCertificateOnlineList(params) {
  return request({
    url: '/certificate/admin/datalist/plan',
    method: 'get',
    params,
  });
}

// 证书列表-线上任务-导出 excel
export function getCertificateOnlineListDownload(params) {
  let url = '/certificate/admin/datalist/plan';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}
